<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Leitor de QrCode</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders d-flex justify-content-center">
            <video id="camera" width="400" height="400" autoplay></video>
          </div>
          
        </div>
      </main>
    </div>

  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import jsQR from 'jsqr';

export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      list: [],
      author:"",
      token: "",
      videoElement: null,
      canvasElement: null,
      canvasContext: null,
      decodedContent: ''
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    setupCanvas() {
      this.canvasElement = document.createElement('canvas');
      this.canvasContext = this.canvasElement.getContext('2d');
    },
    startScanning() {
      const scan = () => {
        if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
          this.canvasElement.height = this.videoElement.videoHeight;
          this.canvasElement.width = this.videoElement.videoWidth;
          this.canvasContext.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
          const imageData = this.canvasContext.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          if (code) {
            alert('QR Code detected:', code); // Imprime o resultado no console
            // Adicione aqui qualquer outra ação que você queira realizar após a detecção
          } else {
            // Continua a verificação se nenhum QR code foi detectado
            this.requestAnimationFrameId = requestAnimationFrame(scan);
          }
        } else {
          // Continua a verificação se o vídeo não está pronto
          this.requestAnimationFrameId = requestAnimationFrame(scan);
        }
      };

      // Inicia o loop de verificação
      this.requestAnimationFrameId = requestAnimationFrame(scan);
    }
  },
  created() {
    if(Cookie.get('_token')){
      this.token = Cookie.get('_token')
    }  
  },
  mounted() {
    this.videoElement = document.getElementById('camera');
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
      .then(stream => {
        this.videoElement.srcObject = stream;
        this.setupCanvas();
        this.startScanning();
      });
  },
  beforeDestroy() {
    // Limpa o loop de animação ao destruir o componente para evitar uso de memória
    cancelAnimationFrame(this.requestAnimationFrameId);
    // Desliga a câmera
    if (this.videoElement && this.videoElement.srcObject) {
      this.videoElement.srcObject.getTracks().forEach(track => track.stop());
    }
  },
};
</script>