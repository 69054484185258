<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Minha conta</h1>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Alterar senha</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nova senha </label>
                  <input v-model="new_password" type="password" class="form-control" name="passnew" placeholder="Digite a nova senha." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Repetir nova senha </label>
                  <input v-model="renew_password" type="password" class="form-control" name="repassnew" placeholder="Repita a  nova senha." />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="chance">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      id: 1,
      name: "",
      email: "",
      tarifa: 0,
      minWP: 0,
      maxWP: 0,
      minInsolation: 0,
      maxInsolation: 0,
      wpp: "",
      status: "",
      description: "",
      author: "",
      token: "",
      taxes: [],
      new_password: '',
      renew_password: '',

    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    chance(){
      if(this.new_password != this.renew_password){
        this.showAlert("error", "A nova senha e a confirmação não estão iguais.");
        return;
      }

      var json = {
        old: this.old_password,
        new: this.new_password,
        renew: this.renew_password,
      }

      this.$http.post("user/change", json, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
  },
  created() {
    if(Cookie.get('_token')){
      this.token = Cookie.get('_token')
    }  
  },
};
</script>
