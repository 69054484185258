import Vue from 'vue'
import VueRouter from 'vue-router'
import Guard from '@/services/middleware'

import Login from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'

import SettingsView from '@/views/SettingsView.vue'

//Clientes
import ClientsListView from '@/views/Clients/ClientsListView.vue'
import ClientsAddView from '@/views/Clients/ClientsAddView.vue'
import ClientsManagerView from '@/views/Clients/ClientsManagerView.vue'
import ClientsReportView from '@/views/Clients/ClientsReportView.vue'
import ClientsCameraView from '@/views/Clients/ClientsCameraView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: Guard.login,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/config',
    name: 'settings',
    component: SettingsView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/relacao',
    name: 'relacao',
    component: ClientsReportView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/participantes',
    name: 'clientes',
    component: ClientsListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/leitor',
    name: 'leitor',
    component: ClientsCameraView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/participantes/adicionar',
    name: 'clientes-add',
    component: ClientsAddView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/participantes/gerenciar/:user',
    name: 'clients-manager',
    component: ClientsManagerView,
    beforeEnter: Guard.auth,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
