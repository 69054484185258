<template>
  <div id="app">
      <router-view />
  </div>
</template>

<style>
@import "@/assets/css/main.css";
</style>

<script>

export default {
  data() {
    return {
      darkMode: false,
      sidebarClosed: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarClosed = !this.sidebarClosed;
    },
  },
  created() {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        this.sidebarClosed = true;
      } else {
        this.sidebarClosed = false;
      }
    });
  },
  watch: {
    darkMode() {
      document.body.classList.toggle("dark");
    },
  },
};
</script>
