<template>
  <div class="nav-top">
    <nav>
      <!-- <div class="w-100 d-flex align-items-center justify-content-end">
        <input v-model="darkMode" type="checkbox" id="theme-toggle" hidden />
        <label for="theme-toggle" class="theme-toggle"></label>
      </div> -->
    </nav>
  </div>
    
  </template>

  <script>
  export default {
    name: 'NavBar',
    data() {
      return {
        darkMode: false,
      };
    },
    watch: {
      darkMode() {
        document.body.classList.toggle("dark");
      },
    },
  };
  </script>
  