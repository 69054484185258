<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Bem-vindo ao II Congresso Médico Acadêmico <br>  de Especialidades Cirúrgicas no Piauí!</h1>
            <ul class="breadcrumb mt-4">
              <li>Estamos entusiasmados em anunciar a realização do II Congresso Médico Acadêmico de Especialidades Cirúrgicas, <br> que acontecerá nos dias 26, 27 e 28 de Setembro de 2024, no auditório Ipê do Centro Universitário UNINOVAFAPI, em Teresina, Piauí.</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      subscribers: 0,
      plans: 0,
      news: 0,
      projects: 0,
      projects_pending: 0,
      amount: 0,
      amount_pending: 0,
      amount_paid: 0,
      users: 0,
      role: 0,
      token: Cookie.get('_token')
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";
      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
      
    },
  },
  created() {
    this.role = this.$store.state.role;
  },
};
</script>