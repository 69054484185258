import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue } from 'bootstrap-vue'

import VueMask from '@devindex/vue-mask';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'sweetalert2/dist/sweetalert2.min.css';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

import VueQuillEditor from "vue-quill-editor";


import ReadonlyDirective from './diretivas/readonly';

Vue.directive('readonly', ReadonlyDirective);

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import store from './store'


const options = {
  confirmButtonColor: '#efce03',
  cancelButtonColor: '#1d1d29',
};

// Vue.prototype.baseStorage = 'http://127.0.0.1:8000/storage/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1';

Vue.prototype.baseStorage = 'https://wteamdev.com/storage/';
axios.defaults.baseURL = 'https://wteamdev.com/api/v1';

axios.defaults.headers.common['Accept'] = 'application/json';
Vue.prototype.$http = axios

Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(VueQuillEditor);
Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')