<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Participante - {{ full_name }}</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome Completo
                  </label>
                  <input v-model="full_name" type="text" class="form-control" name="full_name"
                    placeholder="Nome" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CPF
                  </label>
                  <input v-model="cpf" type="text" class="form-control" name="cpf"
                    placeholder="000.000.000.-00" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nº Inscrição
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg"
                    placeholder="0123456" />
                </div>
              </div>
       
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email"
                    placeholder="teste@teste.com" />
                </div>
              </div>

              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-model="phone" type="text" class="form-control" name="phone"
                    placeholder="(00) 0 0000-0000" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Status
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_active">
                      <option value="0">Não credenciado</option>
                      <option value="1">Credenciado</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                   Pagamento
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_account_status">
                      <option value="1">Não Pago</option>
                      <option value="2">Pago</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="header mt-4">
              <i class="bx bx-code"></i>
              <h3>QrCode</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <img :src="'data:image/svg+xml;base64,' + qrCodeBase64" alt="QR Code" v-if="qrCodeBase64" />

                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="update">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar,
  },
  data() {
    return {
      full_name: '',
      cpf: '',
      rg: '',
      email: '',
      phone: '',
      qrCodeBase64: '',
      is_active: 0,
      id_account_status: 1,
      token: Cookie.get('_token'),
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
    },
    update() {

      var json = {
        full_name: this.full_name,
        cpf: this.cpf,
        rg: this.rg,
        email: this.email,
        phone: this.phone,
        is_active: this.is_active,
        id_account_status: this.id_account_status
      };

      this.$http
        .put("user/clients/"+this.$route.params.user, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },

    load(){
      this.$http
        .get("user/clients/"+this.$route.params.user, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {

            this.full_name = response.data.items.full_name;
            this.cpf = response.data.items.cpf;
            this.rg = response.data.items.rg;
            this.email = response.data.items.email;
            this.phone = response.data.items.phone;
            this.qrCodeBase64 = response.data.qrCode;
            this.is_active = response.data.items.is_active;
            this.id_account_status = response.data.items.id_account_status;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    }
  },
  created(){
    this.load();
  }
};
</script>
